"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Drawers = function Drawers() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $accordionsItems = document.querySelectorAll('.drawers .accordion-item');
    if ($accordionsItems.length) {
      $accordionsItems.forEach(function ($accordionItem) {
        var $accordionTrigger = $accordionItem.querySelector('.accordion-heading');
        var $accordionContent = $accordionItem.querySelector('.accordion-content');
        $accordionTrigger.addEventListener('click', function () {
          if ($accordionItem.classList.contains('is-opened')) {
            $accordionItem.classList.remove('is-opened');
            $accordionContent.style.maxHeight = null;
          } else {
            $accordionItem.classList.add('is-opened');
            $accordionContent.style.maxHeight = "100%";
          }
        });
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();