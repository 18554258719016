"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.HeroBanner = function HeroBanner() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $heroBanners = document.querySelectorAll('.hero-banner');
    if ($heroBanners.length) {
      $heroBanners.forEach(function ($heroBanner) {
        var $slides = $heroBanner.querySelectorAll('.hero-slide');
        if ($slides.length > 1) {
          var flkty = new Flickity($heroBanner, {
            // options
            cellAlign: 'left',
            contain: true,
            prevNextButtons: false,
            imagesLoaded: true,
            autoPlay: 5000,
            wrapAround: true
          });
        }
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();